.header {
  position: absolute;
  top: 0px;
  z-index: 5;
  background-color: white;

  height: fit-content;
  min-height: 40px;
  /* max-height: 40px; */
  min-width: calc(100% - 100px);

  padding: 0px 50px;
  box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 12%);
}

.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app_logo {
  width: 140px;
  /* flex: 0 1 auto;
  aspect-ratio: 281 / 29;
  max-height: 20px; */

  cursor: pointer;
}

.epsa_logo {
  flex: 0 1 227.75px;
  max-height: 40px;
  height: 40px;
}

.epsa_logo_normal {
  height: 40px;
}

.epsa_logo_absolute {
  max-height: 57px;
  position: absolute;
  transform: translate(-17px, -5px);
}

@media only screen and (max-width: 730px) {
  .epsa_logo_absolute {
    right: 0px;
    transform: translate(0, -5px);
  }
  .header {
    width: 100% !important;
  }
}

@media only screen and (max-width: 575px) {
  .header {
    /* min-height: 80px;
    max-height: 80px; */
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px;
  }

  .epsa_logo_absolute {
    right: unset;
    position: relative;
  }
}

.popup_class {
    display: flex;
    align-items: center;
    justify-content: center;

}

.var_margin {
    margin-bottom: 16px !important;
}

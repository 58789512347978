.gradient_border {
    border-bottom: 1px solid rgb(121, 121, 121);
    border-image-source: linear-gradient(
            to left,
            rgb(121, 121, 121) 0%,
            rgb(121, 121, 121) calc(100% - 32px),
            rgba(121, 121, 121, 0) calc(100% - 33px)
    );
    border-image-slice: 1;
}

.input_search_bundle {
    position: relative;
}

.input_search_bundle > img {
    position: absolute;
    margin-top: 14px;
    margin-left: 5px;
}

.input_search_bundle > div > input {
    padding-left: 32px !important;
}

.centerMapBtn {
  position: absolute;
  left: calc(50% - 30px);
  z-index: 1;
  top: 0;

  width: 60px;
  height: 30px;

  border: 1px solid black;

  background-image: url("./../../assets/icons/other/eye.png");
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.4);
}

@media only screen and (max-width: 609px) {
  .centerMapBtn {
    left: calc(75% - 15px);
  }
}

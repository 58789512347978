.alert_control_center_container {
    margin-top: 10px;
}


.menu_form {
    display: flex;
    height: 30px;
    font-size: 16px;
    text-align: center;
}

.menu_form > div {
    display: grid;
    width: 100%;

    cursor: pointer;
    place-items: center;
}


.active {
    color: black;
    background-color: white;
}

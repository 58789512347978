.log_table_pager_container {
    display: flex;
    align-items: center;


    justify-content: space-between;
    max-width: 1000px;
    min-height: 30px;
    margin: 0 auto;
    padding: 20px 0 20px;

    background-color: rgba(255, 255, 255, 0.2);
}

.log_table_pager_container > div {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
}

.log_table_pager_container p,
.log_table_pager_container button {
    min-width: 30px;
    height: clamp(30px, 30px, 30px);

    color: white;
    border-radius: 6px;
    background-color: transparent;
}

.log_table_pager_container > div > p,
.log_table_pager_container > div > button {
    margin: 0 2px;
}


.log_table_pager_container > div > p {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.active {
    font-weight: 700 !important;
    color: black !important;

}

@media (hover: hover) {
    .log_table_pager_container button:enabled:hover {
        background-color: rgb(48, 53, 60);
    }
}

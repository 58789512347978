.alert_card_close {
    display: flex;
    align-items: center;
    justify-content: center;

    height: clamp(364px, 364px, 364px);
    padding: 0 20px 82px;
    font-size: 26px;
}

.alert_card_close > h1 {
    text-align: center;
}

.bigger_title {
    min-width: calc(100% - 40px);
    padding: 16px 20px;

}

@media only screen and (min-width: 1501px) {
    .alert_card_close {
        font-size: 35px;
    }
}




.subsystem_container {
  position: sticky;
  position: -webkit-sticky;
  top: -35px;
  background-color: #f5f5f5;
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px, rgba(0, 0, 0, 0.23) 0 3px 6px;
  z-index: 1;
}

.search_subsystem_layout {
  width: clamp(50px, calc(100% - 80px), 520px);
  margin: 0 auto;
  padding: 35px 40px 25px;
}

.search_input {
  padding-left: 32px !important;
}

.search_style {
  position: absolute;
  margin-top: 14px;
  margin-left: 5px;
}

.rotate {
  color: black;
  transform: rotate(90deg);
  background: transparent !important;
}

.animation {
  transform: rotate(-90deg);
}

.select_place {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (max-width: 609px) {
  .subsystem_container {
    position: unset;
    top: unset;
    z-index: 1;
  }
}

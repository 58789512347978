.global_hover_card {
    position: absolute;
    display: none;
    top: 0;
    width: 100%;
    padding-top: 55px;
    cursor: help;
}

.global_hover_card > div {
    min-width: 245px;
    padding: 20px;
    opacity: 0.3;
    color: white;
    border-radius: 9px;
    background-color: black;

    will-change: opacity;
    transition: opacity 0.3s ease-in-out;
}


@media (hover: hover) {
    .global_hover_card:hover {
        display: flex;
        justify-content: center;
    }

    .global_hover_card:hover > div {
        opacity: 0.9;
    }
}

.box_placeholder {
    max-height: calc(100vh - 160px);

    margin: 30px 0;
    overflow-y: auto;
}


.flexContainer {
    display: flex;
    flex-wrap: wrap;
}

.medium {
    min-height: 243px;
    max-height: 243px;
    overflow-y: hidden;
    /* background-color: rgb(251, 86, 6); */
}

.large {
    min-height: 528px;
    max-height: 528px;
    overflow-y: hidden;
    /* background-color: rgb(51, 213, 83); */
}

.map_box {
    min-width: 270px !important;
    max-width: 270px !important;
    min-height: 539px;
    max-height: 539px;
    padding: 16px 5px 5px !important;
    overflow-y: hidden;
}

/* Scroll bar style */

.box_placeholder::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F5F5F5;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.box_placeholder::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
}

.box_placeholder::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgb(92, 99, 106);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.box_placeholder::-webkit-scrollbar-thumb:hover {
    background-color: #373B40;
}

/* for firefox scroll bar */
.box_placeholder {
    scrollbar-color: #373B40 #F5F5F5;
    scrollbar-width: thin;
}

.weatherBox {
    background-color: rgb(131, 56, 236);
}

.frameBox {
    background-color: #FB5606;
}

.diav {
    background-color: #9A3122;
}

.uv_style {
    background-color: #7698A7 !important;
}

.applicants {
    background-color: #CCC608;
}

.geospacial {
    background-color: #BA73E3;
}

@media only screen and (max-width: 991px) {
    .box_placeholder {
        max-height: calc(100vh - 268px);
        padding: 0 calc((100% - 876px) / 2);
    }
}

@media only screen and (max-width: 875px) {
    .box_placeholder {
        max-width: 587px;
        padding: 0 calc((100% - 587px) / 2);
    }
}

@media only screen and (max-width: 585px) {
    .box_placeholder {
        max-width: 300px;
        max-height: calc(100vh - 348px);
        margin-top: 10px;
        padding: 0 calc((100% - 300px) / 2);
    }
}

@media only screen and (max-width: 575px) {
    .box_placeholder {
        max-height: calc(100vh - 358px);
    }
}

@media only screen and (max-width: 544px) {
    .box_placeholder {
        max-height: calc(100vh - 332px);
    }
}

@media only screen and (max-width: 506px) {
    .box_placeholder {
        max-height: calc(100vh - 306px);
    }
}


.search_form {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px 0 10px;
    background-color: rgba(255, 255, 255, 0.1);
}

.submit_date_picks {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
}

.submit_date_picks > button {
    height: 30px;
    padding: 0 20px;
    border-radius: 3px;
}


.welcome_placeholder {
    padding: 30px 20px;
    font-weight: 700;
    line-height: 1.5;
    color: white;
}

.welcome_placeholder > div {
    position: absolute;
    bottom: 0;
    left: 5px;
}

.welcome_placeholder > div > div:nth-of-type(1){
    justify-content: flex-start;
    max-width: 306px;
    height: 48px;
    margin: 0 5px;
    margin-bottom: 30px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 3px;
    background-color: black;

    cursor: pointer;
}

.welcome_placeholder > div > div:nth-of-type(1) > img {
    margin-left: 10px;
    border-radius: 50%;
    background-color: white;
}

.welcome_placeholder > div > div:nth-of-type(1) > p {
    padding-left: 10px;

}

.welcome_placeholder > div > div:nth-of-type(2){
    justify-content: flex-start;
    max-width: 306px;
    height: 48px;
    margin: 0 5px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 3px;
    background-color: rgb(0, 0, 0);

    cursor: pointer;
}

.welcome_placeholder > div > div:nth-of-type(2) > img {
    margin-left: 10px;
    border-radius: 50%;
    background-color: white;
}

.welcome_placeholder > div > div:nth-of-type(2) > p {
    padding-left: 10px;

}
.welcome_placeholder > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome_placeholder > div > div:last-of-type > img {
    padding: 5px;
    cursor: pointer;
}
.epsa_logo{
    max-width: 306px;
    height: auto;
    margin-top: 10px;
}

@media only screen and (max-width: 991px) {
    .welcome_placeholder {
        text-align: center;
    }

    .welcome_placeholder > div > div:nth-of-type(2),.welcome_placeholder > div > div:nth-of-type(1),.welcome_placeholder > div > div:nth-of-type(3){
        display: none;
    }
    .welcome_placeholder > div{
        position:relative;
    }
}

@media only screen and (max-width: 576px) {
    .welcome_placeholder {
        padding: 20px 15px 10px;
    }
}
.inf_normal{
    border-radius: 50%!important;
    background-color: #fff!important;
}
span.logotxt{
  font-size:11px;
  color:#2a7da7;
  display:block;
  margin:5px;
}
.logoimgtxt{
    background:white;
    clear:both;
    margin-bottom:5px;
}
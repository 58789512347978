.stats_box_placeholder {
    padding: 20px 32px 20px 20px;
    overflow-y: auto;
}

.alert_box {
    min-width: 528px;
    max-width: 528px;
    min-height: 528px;
    max-height: 528px;
    margin: 20px;
    padding: 0;
    overflow-y: hidden;
    /*background-color: #F5F5F5 !important;*/
    border-radius: 12px;
    background-color: transparent;
    box-shadow: rgba(0, 0, 0, 0.56) 0 0 13px 2px;

    /*background: linear-gradient(rgba(255, 165, 0, 1) 76px, rgba(255, 0, 0, 0) 0px),*/
    /*linear-gradient(#F5F5F5 100%, #F5F5F5 76px);*/
    z-index: 3;
    perspective: 1000px;


}

.purge_bg_color {
    background-color: #F5F5F5 !important;
}


.alarm_box {
    animation: red_alert 1s infinite alternate;
    z-index: 2;
}

@keyframes red_alert {
    /*0% {*/
    /*    box-shadow: rgba(255, 0, 0, 0.25) 0px 54px 55px, rgba(255, 0, 0, 0.12) 0px -12px 30px, rgba(255, 0, 0, 0.12) 0px 4px 6px, rgba(255, 0, 0, 0.17) 0px 12px 13px, rgba(255, 0, 0, 0.09) 0px -3px 5px;*/
    /*}*/

    0% {
        box-shadow: rgba(255, 0, 0, 0.56) 0 0 13px 2px;
    }

    100% {
        box-shadow: red 0 0 10px 5px, rgb(255 0 0 / 17%) 0 -23px 25px 0 inset, rgb(255 0 0 / 15%) 0 -36px 30px 0 inset, rgb(255 0 0 / 10%) 0 -79px 40px 0 inset;

    }

}

.alert_box_test {

    min-width: 528px;

    max-width: 528px;
    min-height: 528px;
    max-height: 528px;
    overflow-y: hidden;


    border-radius: 12px;

    cursor: pointer;
    z-index: 3;

}

.fleeto_bg_color {
    background-color: #FFA500 !important;
}

.envi_bg_color {
    background-color: #33D553 !important;
}

.parking_bg_color {
    background-color: #6E93FF !important;
}

.bins_bg_color {
    background-color: #179D00 !important;
}


@media only screen and (max-width: 1500px) {
    .alert_box {
        min-width: 384px;
        max-width: 384px;
        min-height: 528px;
        max-height: 528px;
        overflow-y: hidden;


        background-color: #F5F5F5 !important;
        z-index: 3;

    }
}

.tableau_container {

    width: 100%;
    max-width: 1366px;
    height: 100%;
    margin: 0 auto;

    overflow: auto;
    background-color: white;
}


/* Scroll bar main */
.tableau_container::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F5F5F5;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.tableau_container::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #F5F5F5;
}

.tableau_container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgb(92, 99, 106);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.tableau_container::-webkit-scrollbar-thumb:hover {
    background-color: #373B40;
}

/* for firefox scroll bar */
tableau_container {
    scrollbar-color: #373B40 #F5F5F5;
    scrollbar-width: thin;
}



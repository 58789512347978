.title {
    display: flex;
    align-items: center;
    justify-content: center;

    width: calc(100% - 40px);
    margin-top: 10px;
    padding: 20px;
    text-align: center;
}


.list_placeholder {
    height: 339px;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    list-style: none;
}


.list_placeholder > li {
    position: relative;
    display: flex;

    align-items: center;
    justify-content: center;
    padding: 2px;
    cursor: pointer;
}

.list_placeholder > li > div:last-child {
    min-width: 52px;
}

.action_icons {
    flex-shrink: 0;
}

.lights {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 30px;
    height: 100%;

}

.lights > div:first-of-type {
    width: 10px;
    height: 10px;
    margin-bottom: 3px;
    opacity: 1;
    border-radius: 50%;
}


.alarm_entity {
    animation: changeColor 1s infinite alternate;
}

.bigger_title {
    min-width: calc(100% - 40px);
    min-height: 82px;
    padding: 0px 20px;
    /*font-size: 30px;*/

}

.background_wrapper {
    padding: 20px;
    background-color: #F5F5F5;
}

.remove_entity_padding > div:first-of-type {
    padding-left: 0;
}

.active {
    padding: 0 !important;
    border: 2px solid cornflowerblue;
    border-radius: 6px;
    background-color: #E6E6E6;

}

@keyframes changeColor {
    0% {
        box-shadow: none;
    }

    100% {
        box-shadow: rgba(255, 0, 0, 0.17) 0 -23px 25px 0 inset, rgba(255, 0, 0, 0.15) 0 -36px 30px 0 inset, rgba(255, 0, 0, 0.1) 0 -79px 40px 0 inset;
    }
}

@media (hover: hover) {
    .list_placeholder > li:hover {
        background-color: #E6E6E6;
    }
}


@media only screen and (min-width: 1501px) {
    .list_placeholder,
    .bigger_title {
        font-size: 30px;
    }

    .list_placeholder span,
    .list_placeholder p {
        font-size: 20px;
    }
}





.card_inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;

    transform-style: preserve-3d;
}


.card_inner > div {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden !important;
    backface-visibility: hidden !important;
}

.card_inner > div.card_back {

    transform: rotateY(180deg) !important;
}


.fleeto_bg_color {
    background-color: #FFA500 !important;
}

.rotate {
    transform: rotateY(180deg) !important;
}







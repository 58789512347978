.metrics_pm {
    display: flex;
    margin: 20px 0;
}

.metrics_pm > div {
    width: 100px;
    font-size: 14px;
    text-align: center;
}

.metrics_pm > div p {
    margin-bottom: 10px;
    padding: 0 5px;
    color: black!important;
}

.metrics_pm > div h1 {
    margin-bottom: 15px;
    font-size: 16px;
    color: black!important;
}

.metrics_pm > div:not(:nth-of-type(1)) {
    color: #FEF4EF;
}

.metrics_pm > div:nth-of-type(2) {
    background-color: #F41000;
}

.metrics_pm > div:nth-of-type(3) {
    background-color: #F78000;
}

.metrics_pm > div:nth-of-type(4) {
    background-color: #35A353;
}

.metrics_pm > div:nth-of-type(5) {
    background-color: #44CA68;
}

.metrics_pm > div:nth-of-type(6) {
    background-color: #4FE676;
}

.metrics_pm > div:nth-of-type(7) {
    color: black;
    background-color: rgb(99, 220, 138);
}


.column_metrics > div > p:nth-of-type(1) {
    color: black;
    background-color: #F6F6F6;
}

.column_metrics > div > p:nth-of-type(2) {
    background-color: #33D553;
}

.column_metrics > div > p:nth-of-type(3) {
    background-color: #FFBE0D;
}

.column_metrics > div > p:nth-of-type(4) {
    background-color: #FB5606;
}

.column_metrics > div > p:nth-of-type(5) {
    background-color: #F61D45;
}

.column_metrics > div > p:nth-of-type(6) {
    background-color: #B10D67;
}

.column_metrics > div > p:nth-of-type(7) {
    background-color: #5E1C73;
}

.sticky_title {
    position: sticky;
    position: -webkit-sticky; /* for safari */
    top: 0;
    margin-bottom: 20px;
    padding-top: 10px;

    padding-bottom: 0.1px;
    background-color: rgb(104, 104, 104);
    z-index: 3;
}

.info_title a {
    color: white;
}

.height_unset {
    max-height: unset;
}

.gradient_line_style {
    border-bottom: 2px solid;
    border-image-source: linear-gradient(
            to left,
            rgba(255, 255, 255, 0) 5%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 0) 95%
    );
    border-image-slice: 1;
}

.vertical_side_panel {
    position: absolute;
    top: 0;

    right: 0;
    width: 320px;
    height: 100%;
    padding: 0 20px;
    overflow-x: hidden;
    overflow-y: auto;
    color: white;
    background-color: rgb(104, 104, 104);
    box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 12%);

    transition: transform 0.15s ease-out;

    transform: translateY(-100%);
    z-index: 1;
}

.side_panel_height {
    transition: transform 0.25s ease-in;
    transform: translateY(0%);
}

.close_button_sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 18px;
    float: right;
    z-index: 1;
}

.close_button {
    position: absolute;
    padding: 0 6.3205px 4px;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;

    cursor: pointer;
    transform: translate(-6.3205px, 7px);
}

/* Scroll bar list place */
.vertical_side_panel::-webkit-scrollbar {
    display: none;
}

/* for firefox scroll bar */
.vertical_side_panel {
    scrollbar-width: none;
}

@media only screen and (max-width: 360px) {
    .vertical_side_panel {
        width: calc(100% - 40px);
    }
}

.vertical_swticher {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 40px 0 10px;
}


.vertical_swticher > button:first-of-type {
    height: 30px;
    text-decoration: none;
    border: none;

    background-color: white;
    background-image: linear-gradient(-54deg, white, white 50%, gray);
    cursor: pointer;
}

.vertical_swticher > h1 {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: white;
    background-color: transparent;
}

.vertical_swticher > button:first-of-type {
    position: absolute;
    right: 20px;
    padding: 0 15px;
}




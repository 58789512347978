.hover_card_container {
    position: absolute;
    left: 0;
    width: 100%;
    margin-top: -19px;
    padding-top: 29px;

    z-index: 1;
}

.hover_card_container > section {
    width: calc((502px * 70) / 100);
    padding: 10px;
    font-size: 14px;
    opacity: 0;
    color: white;
    border-radius: 6px;
    background-color: black;
    will-change: opacity;
    transition: opacity 0.3s ease-in-out;
    transform: translateX(calc(-275px + (502px * 15) / 100));
}

.hover_card_container > section > h1 {
    font-size: 16px;
}


@media (hover: hover) {
    .hover_card_container:hover > section {
        opacity: 0.9;
    }
}

@media only screen and (max-width: 1500px) {
    .hover_card_container > section {

        width: 292px;

        transform: translateX(-96px);
    }
}


.card_container {
    display: flex;
    align-items: center;
    flex-direction: column;

    margin: 20px 0;
    padding: 30px 2px 40px;
    border: 2px solid #DCDCDC;

    border-radius: 9px;
}


.card_actions {
    position: relative;
    display: flex;

    align-items: center;
    justify-content: flex-end;
    width: 70%;
    margin-top: 20px;
}


.purge_button {
    height: 30px;
    border: 0;
    border-radius: 6px;
    outline: none;
    background-color: white;
    cursor: pointer;
}


.send_button {
    /*width: 90px;*/
    margin-left: 10px;
    padding: 0 15px;
}

.clear_button {
    width: 30px;
    color: white;
    background-color: #9A3122;
}

.history_container {
    position: relative;
    margin-right: 10px;
}


.history_container > div {
    display: none;
}

@media (hover: hover) {
    .history_container:hover > div {
        display: block;
    }

    .card_container:hover {
        padding: 28px 0 38px;
        border: 4px solid #DCDCDC;
    }
}

.vertical_content_container {
    margin-top: 40px;
}

.vertical_content_container > h1 {
    font-size: 16px;
    text-align: center;
}


.vertical_content_container > ul {
    margin: 20px 0 0;
    padding: 0 0 0 20px;
}

.legend_fixed_container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 15vw;
    bottom: 80px;
    width: calc(70vw - 20px - 2px);
    margin: 0;
    padding: 10px;
    overflow: hidden;
    list-style: none;
    border: 1px solid silver;
    border-radius: 8px;
    background-color: tan;
    will-change: right, width;
    transition: right 0.3s ease-in-out, width 0.6s ease-in-out;
    z-index: 3;
}


.close_btn {
    position: absolute;
    top: 0;
    right: 5px;
    opacity: 0.7;
    cursor: pointer;
}


.legend_fixed_container > li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
}

.legend_fixed_container > li > img {
    width: 38px;
    margin-right: 5px;
}

.close_legend_container {
    right: 0;
    width: 50px;
}

.close_legend_container {
    padding: 0;
}

@media (hover: hover) {
    .close_btn:hover {
        opacity: 1;
    }
}

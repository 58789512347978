.subTitle_placeholder {
  min-width: 100%;
  max-width: 100%;
  min-height: 38px;
  max-height: 38px;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-align: center;
  color:black!important;
}

.subTitleBold {
  font-weight: 700;
}
.SubTitle_subTitle_placeholder{
	 color:black!important;
}
.Title_title__37A39{
	 color:white!important;
}
.vertical_history_container {
    position: relative;

    width: 100%;
    max-width: 1140px;
    margin: 20px auto 0;

    padding: 15px 0;
}


.container {
    position: relative;
    width: calc(100% - 30px - 37.5px - 50px);
    background: inherit;
}


.container.right {

    left: calc(37.5px + 50px);

    padding: 15px 0 15px 30px;
}

.container.left {
    padding: 15px 30px 15px 0;
}

.container::after {
    position: absolute;
    top: calc(50% - 10px);
    right: -10px;
    width: 16px;
    height: 16px;
    content: '';
    border: 2px solid #006E51;
    border-radius: 16px;
    background: #FFFFFF;
    z-index: 1;
}

.container.right::after {
    left: -10px;
}

.container::before {
    position: absolute;
    top: calc(50% - 1px);
    right: 8px;
    width: 50px;
    height: 2px;
    content: '';
    background: #006E51;
    z-index: 1;
}

.container.right::before {
    left: 8px;
}


.container > div:first-of-type {
    position: absolute;
    top: calc(50% - 22.5px);
    width: 40px;
    height: 40px;
    padding: 5px 2.5px 0;
    z-index: 2;
}


.container.left > div:first-of-type {
    right: calc(-50px - 37.5px);
}

.container.right > div:first-of-type {
    left: calc(-50px - 37.5px);
}

.container > div:nth-of-type(2) {
    position: absolute;
    display: inline-block;
    top: calc(50% - 18px);
    padding: 3px 2px 0 1px;
    color: #006E51;
    border: 2px solid #006E51;
    border-radius: 50%;

    z-index: 1;

}

.container img {
    width: 100%;
    border-radius: 50%;
    background-color: white;
}


.container > div:nth-of-type(2) {
    width: 30px;
    height: 30px;
}

.container > div:nth-of-type(2) > div {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 20px;
    height: 20px;
    border-radius: 50%;

    background-color: white;
}


.container.left > div:nth-of-type(2) {
    right: 56px;
}

.container.right > div:nth-of-type(2) {
    left: 56px;
}


.container section {
    position: relative;
    padding: 30px 90px 30px 30px;
    border-radius: 0 150px 150px 0;
    background: #F6D155;
}

.container.right section {
    padding: 30px 30px 30px 90px;
    border-radius: 150px 0 0 150px;
}


.container section h1 {
    margin: 0 0 10px 0;
    font-size: 18px;
    font-weight: normal;
    color: #006E51;
}


.container section p {
    margin: 0;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
}


/*@media only screen and (max-width: 767.98px) {*/
@media only screen and (max-width: 1500px) {

    .container {
        left: 0px;
        width: calc(100% - 18.5px);
    }

    .container img {
        width: calc(100% - 7px);
        margin-top: 1.5px;
        margin-left: 2.5px;

    }


    .container.right {
        left: 18.5px;
        padding: 15px 0 15px 0;
    }

    .container.left {
        padding: 15px 0 15px 0;
    }

    .container.left::after,
    .container.right::after {
        display: none;
    }

    .container.left::before,
    .container.right::before {
        display: none;
    }


    .container.right > div:nth-of-type(2) {
        right: auto;
        left: -18.5px;
    }

    .container.left > div:nth-of-type(2) {
        right: -18.50px;
        left: auto;
    }

    .container > div:nth-of-type(2) > div {
        display: none;
    }


    .container.right > div:first-of-type {
        right: auto;
        left: -23px;
    }

    .container.left > div:first-of-type {
        right: -23px;
        left: auto;
    }


}

.container > div:first-of-type > div {
    position: absolute;
    display: none;
    top: 0;
    width: 100%;
    padding-top: 55px;
    cursor: help;
}

.container > div:first-of-type > div > div {
    right: 0;
    min-width: 245px;
    padding: 20px;
    opacity: 0.3;
    color: white;
    border-radius: 9px;
    background-color: black;
    will-change: opacity;
    transition: opacity 0.3s ease-in-out;
}

.container.left > div:first-of-type > div {
    justify-content: flex-end;
    transform: translateX(-3px);
}

.container.right > div:first-of-type > div {
    justify-content: flex-start;
    transform: translateX(3px);
}

@media (hover: hover) {
    .container > div:first-of-type:hover > div {
        display: flex;
    }


    .container > div:first-of-type > div:hover > div {
        opacity: 0.9;
    }
}

.search_subsystem_layout {
    padding: 35px 40px 15px;
}

.search_style {
    position: absolute;
    margin-top: 14px;
    margin-left: 5px;
}

.search_input {
    padding-left: 32px !important;
}

.rotate {
    color:black;
    transform: rotate(90deg);
}

.animation {
    transform: rotate(-90deg);
}

.select_place {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

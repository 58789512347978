.round_bins {
    display: flex;
    margin: 20px 0;
}

.round_bins > div {
    width: 100px;
    font-size: 14px;
    text-align: center;
}

.round_bins > div {
    width: auto;
}

.round_bins > div h1 {
    margin-bottom: 15px;
    font-size: 16px;
}

.round_bins > div p {
    margin-bottom: 10px;
    padding: 0 5px;
}


/* metrics */
.round_bins > div:first-of-type {
    min-width: 100px;
}

.round_bins > div:not(:first-of-type) {
    width: 100%;
}

.round_bins > div:not(:nth-of-type(1)):not(:nth-of-type(2)):not(:nth-of-type(3)):not(:nth-of-type(4)) {
    color: #FEF4EF;
}

.round_bins > div:nth-of-type(2) {
    background-color: #DFD6EA;
}

.round_bins > div:nth-of-type(3) {
    background-color: #BFAED6;
}

.round_bins > div:nth-of-type(4) {
    background-color: #A086C1;
}

.round_bins > div:nth-of-type(5) {
    background-color: #805EAD;
}

.round_bins > div:nth-of-type(6) {
    background-color: #623899;
}

.round_bins > div:nth-of-type(7) {
    background-color: #4E2C7A;
}

.round_bins > div:nth-of-type(8) {
    background-color: #44266B;
}

.round_bins > div:nth-of-type(9) {
    background-color: #3A215B;
}

.round_bins > div:nth-of-type(10) {
    background-color: #311B4C;
}

.round_bins > div:nth-of-type(11) {
    background-color: #311B4C;
}

/* links */
.nfl a, .mlb a, .nhl a, .pga a {
    text-decoration: none;
    transition: color 0.2s ease-out;
}

.nfl a {
    color: rgba(79, 192, 210, 1);
}

.nfl a:hover {
    color: rgba(38, 134, 149, 1);
}

.mlb a {
    color: rgba(82, 210, 154, 1);
}

.mlb a:hover {
    color: rgba(39, 151, 102, 1);
}

.nhl a {
    color: rgba(231, 196, 104, 0.7);
}

.nhl a:hover {
    color: rgba(201, 154, 32, 0.7);
}

.pga a {
    color: rgba(235, 118, 85, 1);
}

.pga a:hover {
    color: rgba(195, 61, 23, 1);
}

/* wrapper */
.wrapper {
    position: relative;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px 0 0 0;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.9);
    background: rgba(255, 255, 255, 0.1);
}

/* lists */
.row ul {
    margin: 0;
    padding: 0;
}

.row ul li {
    display: inline-block;
    width: 20%;
    margin: 0;
    font-size: 16px;
    font-weight: normal;
    box-sizing: border-box;
    list-style: none;
}

@media only screen and (max-width: 767px) and (min-width: 480px) {
    .row ul li {
        font-size: 13px;
    }
}

@media only screen and (max-width: 479px) {
    .row ul li {
        font-size: 13px;
    }
}

.title ul li {
    padding: 15px 13px;
}

.row ul li {
    padding: 5px 10px;
}

/* rows */
.row {
    position: relative;
    height: 30px;
    padding: 20px 0;
    font-size: 0;
    overflow: hidden;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-out;
}

.row:hover {
    height: 65px;
    background-color: rgba(26, 26, 26, 0.9);
}

@media only screen and (max-width: 767px) {
    .row:hover {
        height: 85px;
    }
}

@media only screen and (max-width: 359px) {
    .row:hover {
        height: 105px;
    }
}

.title {
    height: 45px;
    padding: 25px 0 5px 0;
    font-size: 0;
    border-left: 3px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.1);
}

.title:hover {
    height: 45px;
    border-left: 3px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.1);
}

@media only screen and (max-width: 767px) {
    .title-hide {
        display: none;
    }
}

.nfl {
    border-left: 3px solid rgba(28, 97, 108, 1);
}

.nfl:hover {
    border-left: 3px solid rgba(79, 192, 210, 1);
}

.mlb {
    border-left: 3px solid rgba(29, 110, 75, 1);
}

.mlb:hover {
    border-left: 3px solid rgba(82, 210, 154, 1);
}

.nhl {
    border-left: 3px solid rgba(157, 121, 25, 0.7);
}

.nhl:hover {
    border-left: 3px solid rgba(231, 196, 104, 0.7);
}

.pga {
    border-left: 3px solid rgba(149, 47, 18, 1);
}

.pga:hover {
    border-left: 3px solid rgba(235, 118, 85, 1);
}

/* row one - fadeIn */
.row-fadeIn-wrapper {
    position: relative;
    height: 0;
    font-size: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 0.2s ease-out;
    animation: fadeIn 0.4s ease-out 2s 1 alternate;
    animation-fill-mode: forwards;
}

.row-fadeIn-wrapper:hover {
    height: 35px;
}

@media only screen and (max-width: 767px) {
    .row-fadeIn-wrapper:hover {
        height: 55px;
    }
}

@media only screen and (max-width: 359px) {
    .row-fadeIn-wrapper:hover {
        height: 75px;
    }
}

.fadeIn {
    position: absolute;
    width: 100%;
    padding: 20px 0;
    font-size: 0;
    transition: all 0.2s ease-out;
}

.fadeIn:hover {
    background-color: rgba(26, 26, 26, 0.9);
}

/* row two - fadeOut */
.row-fadeOut-wrapper {
    position: relative;
    height: 65px;
    font-size: 0;
    overflow: hidden;
    opacity: 1;
    transition: all 0.2s ease-out;
    animation: fadeOut 0.4s ease-out 8s 1 alternate;
    animation-fill-mode: forwards;
}

.row-fadeOut-wrapper:hover {
    height: 100px;
}

/* update content */
.update-row {
    animation: update 0.5s ease-out 12s 1 alternate;
}

.update1 {
    position: absolute;
    display: inline-block;
    top: 25px;
    opacity: 1;
    animation: update1 1s ease-out 12s 1 alternate;
    animation-fill-mode: forwards;
}

.update2 {
    position: absolute;
    display: inline-block;
    top: 25px;
    opacity: 0;
    animation: update2 4s ease-out 13s 1 alternate;
    animation-fill-mode: forwards;
}

/* more content */
ul.more-content li {
    position: relative;
    display: block;
    top: 22px;
    width: 100%;
    height: 50px;
    margin: 0;
    padding: 10px 13px;
    font-size: 13px;
    color: rgba(128, 128, 128, 0.9);
}

@media only screen and (max-width: 767px) {
    ul.more-content li {
        font-size: 11px;
    }
}

/* small content */
.small {
    margin: 0;
    padding: 0 10px;
    font-size: 10px;
    color: rgba(102, 102, 102, 0.9);
}

@media only screen and (max-width: 767px) {
    .small {
        display: none;
    }
}

/* keyframe animations */
@keyframes fadeIn {
    from {
        background: rgba(255, 255, 255, 0.1);
        opacity: 0;
        padding: 0;
    }
    to {
        background: rgba(51, 51, 51, 0.1);
        opacity: 1;
        padding: 0 0 65px 0;
    }
}

@keyframes fadeOut {
    from {
        background: rgba(51, 51, 51, 0.1);
        opacity: 1;
        height: 65px;
    }
    to {
        background: rgba(255, 255, 255, 0.1);
        opacity: 0;
        height: 0;
    }
}

@keyframes update {
    0% {
        background: rgba(51, 51, 51, 0.1);
    }
    50% {
        background: rgba(255, 255, 255, 0.1);
    }
    100% {
        background: rgba(51, 51, 51, 0.1);
    }
}

@keyframes update1 {
    0% {
        opacity: 0;
    }
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes update2 {
    0% {
        opacity: 0;
        color: rgba(255, 255, 255, 0.9);
    }
    20% {
        opacity: 1;
        color: rgba(82, 210, 154, 1);
    }
    100% {
        opacity: 1;
        color: rgba(255, 255, 255, 0.9);
    }
}
